import React, {useEffect, useState} from "react";
import styles from './Main.module.scss';
import Header from "./Header/Header";
import Balance from "./Balance/Balance";
import bgDark from '../../assets/icons/bgDarkMain.png';
import bgLight from '../../assets/icons/bgLightMain.png';
import Menu from "./Menu/Menu";
import Content from "./Content/Content";
import {getUserInfo, getWalletsInfo, refreshAccessToken} from "../../api/api";
import {useAppDispatch} from "../../hooks/redux";
import {setBalance, setMainList} from "../../store/main-slice";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getTransactionEvent, getUserId} from "../../store/selectors";
import {setEvent, setNetworkWallets, setUserWallets} from "../../store/wallet-slice";
import {setAcToken, setTwoFactorAuth} from "../../store/account-slice";
import {Preloader} from "../Common/Preloader/Preloader";
import EventPopup from "./EventPopup/EventPopup";

const Main = ({theme}) => {
    const dispatch = useAppDispatch();
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const event = useSelector(getTransactionEvent);
    const [isLoading, setIsLoading] = useState(false);
    const [isShow, setIsShow] = useState(false);


    const onClose = () => {
        setIsShow(false);
    }

    if (accessToken) {
        const chatSocket = new WebSocket(`wss://testbot.gr-pro.org/ws/chat/?token=${accessToken}`);

        chatSocket.onmessage = function (e) {
            const data = JSON.parse(e.data);
            const message = data["message"];
            if (message && message.isProcessing) {
                if (event !== message) {
                    dispatch(setEvent(message))
                }
            } else {
                dispatch(setEvent(null))
            }
        };
    }

    useEffect(() => {
        if (telegramId && accessToken) {
            getUserInfo(telegramId, accessToken).then(response => {
                if (response && response.detail && response.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getUserInfo(telegramId, r.access_token).then(response => {
                                if (response && response.total_balance && response.cryptocurrency) {
                                    dispatch(setBalance(response.total_balance));
                                    dispatch(setMainList(response.cryptocurrency));
                                    dispatch(setTwoFactorAuth(response.fa_enable));
                                }
                            })
                        }
                    });
                }
                if (response && response.total_balance && response.cryptocurrency) {
                    dispatch(setBalance(response.total_balance));
                    dispatch(setMainList(response.cryptocurrency));
                    dispatch(setTwoFactorAuth(response.fa_enable));
                }
            });
        }
    }, [telegramId]);

    useEffect(() => {
        if (telegramId && accessToken) {
            getWalletsInfo(telegramId, accessToken).then(response => {
                if (response && response.detail && response.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getWalletsInfo(telegramId, r.access_token).then(response => {
                                if (response && response.tokens) {
                                    dispatch(setUserWallets(response.tokens))
                                    dispatch(setNetworkWallets(response.wallets))
                                }
                            })
                        }
                    });
                }
                if (response && response.tokens) {
                    dispatch(setUserWallets(response.tokens))
                    dispatch(setNetworkWallets(response.wallets))
                }
            })
        }
    },[accessToken])

    return (
        <div className={styles.main}
             style={theme === 'dark' ? {backgroundImage: `url(${bgDark})`} : {backgroundImage: `url(${bgLight})`}}>
            <div className={styles.main__headerContainer}>
                <Header setIsLoading={setIsLoading} />
                <Balance/>
                <Menu/>
            </div>
            <Content event={event} setIsShow={setIsShow}/>
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader />
                </div>
            )}
            <EventPopup isShow={isShow} event={event} onClose={onClose} />
            {isShow && (
                <button className={styles.closer} onClick={() => setIsShow(false)}/>
            )}
        </div>
    )
}

export default Main;